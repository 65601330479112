import { Location, LocationResponse } from '../types';
import { client } from './index';

const getLocations = async (page: number) => {
  const params = new URLSearchParams();
  if (page) params.append('page', page.toString());

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const getLocationsUrl = `/react_get_locations${queryString}`;

  const response = await client.get<LocationResponse>(getLocationsUrl);
  const { data } = response;
  return data;
};

const getLocationsWithSearch = async (page: number, search?: string) => {
  const params = new URLSearchParams();
  if (page) params.append('page', page.toString());
  if (search) params.append('search', search);

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const getLocationsUrl = `/v2/react_get_locations${queryString}`;

  const response = await client.get<LocationResponse>(getLocationsUrl);
  const { data } = response;
  return data;
};

const getLocation = async (locationId: number) => {
  const response = await client.get<Location>(`/react_get_location/${locationId}`);
  const { data } = response;
  return data;
};

const getLocationsByIds = async (locationIds: string) => {
  const response = await client.get<Location[]>(`/react_get_locations_by_ids/${locationIds}`);
  const { data } = response;
  return data;
};

export const locationsApi = {
  getLocations,
  getLocationsWithSearch,
  getLocation,
  getLocationsByIds,
};
