export const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const noWhiteSpaceRegex = /^\s*$/;

export const onlyNumbersRutRegex = /^[0-9]+-[0-9kK]{1}$/;

export const rutRegex = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/;

export const rucRegex = /^(10|15|16|17|20)\d{9}$/;

export const nitRegex = /^(\d{1,3}(?:\.\d{1,3}){2}-[\d])$/;

export const nuipRegex = /^\d{1,11}$/;

export const urlRegex =
  /^(https?:\/\/|www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

export const googleSheetsUrlRegex = /^https:\/\/docs.google.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+/;
