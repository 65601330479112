import { FormAnswersResponse } from '../types';
import { client } from './index';

async function getFormAnswers(
  searchQuery?: string,
  page?: number,
  deleted?: boolean,
  startDate?: string,
  endDate?: string,
  userId?: number[],
  statusId?: number[],
  code?: number,
  locationId?: number[],
  formId?: number,
  question?: string,
  answer?: string,
): Promise<FormAnswersResponse> {
  const params = new URLSearchParams();

  if (page) params.append('page', page.toString());
  if (searchQuery) params.append('search_form_name', searchQuery);
  if (deleted) params.append('deleted', deleted.toString());
  if (startDate) params.append('start', startDate);
  if (endDate) params.append('end', endDate);
  if (userId && userId.length > 0) params.append('user_id', userId.join(','));
  if (statusId && statusId.length > 0) params.append('state_id', statusId.join(','));
  if (code) params.append('code', code.toString());
  if (locationId && locationId.length > 0) params.append('location_id', locationId.join(','));
  if (formId) params.append('task_form_id', formId.toString());
  if (question) params.append('search_question_name', question);
  if (answer) params.append('search_value', answer);

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const url = `/form_answers_react${queryString}`;

  const response = await client.get<FormAnswersResponse>(url);
  const { data } = response;
  return data;
}

export const formAnswersApi = {
  getFormAnswers,
};
