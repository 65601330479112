export const English = {
  lang: 'en',
  wizard: {
    createAccount: 'Create new account',
    noCreditCard: 'No credit card needed, cancel any time',
    name: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    companyName: 'Company',
    role: 'Role',
    dialCode: 'Country',
    phoneNumber: 'Phone Number',
    fullNumber: 'Country and phone number',
    password: 'Password',
    saveButton: 'Send',
    roles: [
      'CEO / President',
      'COO/CSO',
      'Security Director',
      'Finance - Professional',
      'Finance - VP / Executive',
      'IT - Professional',
      'IT - VP / Executive',
      'Marketing',
      'Operations - Professional',
      'Operations - VP / Executive',
      'Sales',
      'Sales - VP / Executive',
      'Service - Professional',
      'Service - VP / Executive',
      'Consultant',
      'Teacher',
      'Student',
      'Other',
    ],
    captchaError: 'Captcha has not been validated',
    inputError: 'Complete field',
    emailExistsError: 'Email address is already in use',
    passwordError: 'Must be at least 6 characters long',
    phoneNumberError: 'Only numbers',
    emailError: 'Invalid email',
    emailInfo: 'With this email you can login into app and web platform',
    privacyMessage: 'Upon clicking you agree that you accept our ',
    privacy: 'Privacy Policy',
    preparingForm: 'We are creating your account and preparing the form',
    preparingForm2: 'This could take a few seconds',
    preparingForm3: 'We are preparing the form',
    userHasAccount: 'Already have an account?',
    login: 'Login',
  },
  wizardFinish: {
    buildYourFirstForm: 'Build your first mobile form',
    formTemplates: 'Form templates',
    createForm: 'Create form',
    welcome: 'Welcome!',
    welcomeDescription1: 'Starting now, you can ',
    welcomeDescription2:
      'use the Web App and the Mobile App using the credentials you just entered.',
    welcomeDescription3:
      'Remember, to begin collecting data, simply download the App from App Store or Play Store on your phone or tablet.',
  },
  task_assigner: {
    assigned: 'Assigned',
    completed: 'Completed',
    pending: 'In progress',
    incomplete: 'Incomplete',
    states: {
      completed: 'Completed',
      pending: 'In progress',
      incomplete: 'Incomplete',
      rejected: 'Rejected',
      programmed: 'Programmed',
      sent: 'Sent',
    },
    cards: {
      totalTasks: 'Total tasks',
      completed: 'Completed',
      pending: 'In progress',
      incomplete: 'Incomplete',
      rejected: 'Rejected',
      programmed: 'Programmed',
      sent: 'Sent',
    },
    filters: 'Filters',
    new_task: 'New Task',
    import: 'Import',
    edit_task: 'Edit Task',
    tasks: 'Tasks',
    massive_search: 'Search by user, form or place ...',
    user: 'User',
    form: 'Form',
    place: 'Place',
    date: 'Date',
    date_and_time: 'Date and Time',
    gap: 'Gap',
    state: 'State',
    save: 'Save',
    users: 'Users',
    description: 'Description',
    hour: 'Hour',
    alert: 'alert',
    delete: 'Delete',
    cancel: 'cancel',
    accept: 'agree',
    notify: 'Notify',
    notify_user: 'Do you want to notify to ',
    sure: 'Are you sure?',
    delete_task: 'You will delete a task',
    delete_tasks: 'Do you want to delete this tasks: ',
    reset: 'Reset',
    old_version: 'Old Version',
    task_created: 'Task Created',
    our_problem: 'We have problem',
    task_update: 'Task updated successfully',
    export: 'Export',
    date_necessary: 'A date is required',
    required: 'Required',
    blanck: 'There are empty fields',
    notification_sent: 'Notification sent successfully',
    notifications_sent: 'Notifications sent successfully',
    tasks_delete: 'Tasks deleted successfully. Please wait a few seconds for them to be reflected',
    task_delete: 'Task deleted successfully',
    new_place: 'New Place',
    start: 'Start',
    end: 'End',
    history: 'History',
    actions: 'Actions',
    dobleClick: 'Double click on task = open details',
    shiftMouse: 'shift + mousewheel = move timeline left/right',
    altMouse: 'alt + mousewheel = zoom in/out',
    timeline: 'Timeline',
    taskAssignID: 'Task assign ID',
    selected_range: 'Selected range',
    notifications: 'Add notifications',
    add_notification: 'Add a notification',
    seconds: 'seconds',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years',
    notification: 'Notification',
    email: 'Email',
    max_one_date: 'Maximum 1 date in edit mode',
    max_one_user: 'Maximum 1 user in edit mode',
    no_location_warning: 'A location has not been specified, or may have been erased.',
    showing_results_for: 'Showing results for',
    task: 'Task',
    no_tasks_available: 'No tasks available at this moment',
    gap_hours: 'Duration of tasks in hours',
    allow_late_submission: 'Allow the task to be answered after the due date has been reached',
    add_tasks: 'Add tasks',
    add_tasks_caption:
      'You can quickly add tasks by separating them with commas "," or pressing Enter',
    configuration: 'Configuration',
    voluntary_task: 'Make this task voluntary (All users can accept/reject the task)',
    mandatory_task: 'Make this task mandatory (All users must answer to the task)',
    notification_type: 'Notification type',
    notification_time: 'Time',
    notification_period: 'Time period',
    app_notification: 'App notification',
    confirmation_title_prefix: 'Are you sure you want to create ',
    confirmation_title_suffix: ' tasks?',
    confirmation_title_suffix_one: 'tasks for',
    confirmation_title_suffix_two: 'days?',
    edit_confirmation_title: 'Are you sure you want to edit this task?',
    success_message:
      'Tasks created successfully! Please wait a few seconds for them to be reflected. Remember to refresh the page to view them.',
    update_success_message: 'Task updated successfully!',
    forms_info:
      'If you select multiple forms, you will create multiple tasks with the selected ones.',
    edit: 'Edit',
    show_answer: 'Show answer',
    notify_title: 'Are you sure you want to notify the following user?',
    bulk_notify_title: 'Are you sure you want to notify the following users?',
    delete_title: 'Are you sure you want to delete the following task?',
    delete_description: 'It will be deleted and you will not be able to recover it',
    bulk_delete_title_one: 'Are you sure you want to delete',
    bulk_delete_title_two: 'tasks?',
    bulk_delete_description:
      'If you delete them, they will disappear from the list of pending tasks of the users and they will not be able to answer them',
    bulk_actions: 'Bulk Actions',
    creation_date: 'Creation Date',
    start_date: 'Start Date',
    answers: 'Answers',
    duration: 'Duration:',
    answered: 'answered',
    out_of_time: 'Out of time',
    late: 'late',
    no_location: 'No location',
    task_does_not_exist: 'The task does not exist',
    location_copied: 'Location copied to clipboard',
    completed_tasks_cannot_be_deleted: 'Completed tasks cannot be deleted',
  },
  form_answer: {
    answer_again: 'Submit another answer',
    success_message: 'We have received your answer correctly',
    submit_button: 'Submit',
    edit_button: 'Edit',
    incomplete_edit_alert: 'You must answer all required questions',
    incomplete_submit_alert: "You haven't entered any answer yet",
    error_submit_alert: 'There was an error uploading your answer',
    error_edit_alert: 'There was an error editing your answer',
    no_edit_alert: "You haven't edited the original answer yet",
    no_answer_alert: 'Your answer is empty',
    required_question: 'This question is required',
    no_options: 'No Options',
    invalid_question_type_1: 'Question of type  ',
    invalid_question_type_2:
      'This type of question cannot be answered/edited in our web platform. Please download our mobile application',
    some_invalid_question_types:
      'Warning: This form has question types that are not supported by the web version of DataScope.',
    download_mobile: 'Download our mobile application here',
    download_mobile_link: 'https://datascope.page.link/H3Ed',
    answer_placeholder: 'Your answer',
    simple_text_placeholder: 'Your text answer',
    text_rut_placeholder: 'Your RUT answer',
    text_container_placeholder: 'Your container ID',
    invalidRutError: 'Invalid RUT',
    invalidContainerError: 'Invalid container ID',
    text_email_placeholder: 'Your email answer',
    select_your_date: 'Select a date',
    select_your_datetime: 'Select a datetime',
    select_your_time: 'Select a time',
    datetime_errors: {
      invalidDate: 'Invalid date',
      minDate: 'The date is less than the minimum allowed',
      maxDate: 'The date is greater than the maximum allowed',
    },
    add_repeatable_questions: 'Add another group of questions',
    signature_type: 'Signature',
    photo_type: 'Photo',
    barcode_type: 'Barcode',
    filter_elements: 'Search elements...',
    upload_image: 'Upload Image',
    no_image_loaded: 'No image has been uploaded yet',
    image_max_size_exceeded: 'One or more of your files exceed the maximum allowed size of',
    image_max_amount_exceeded_part_one: 'You can upload up to',
    image_max_amount_exceeded_part_two: 'images per comment',
    image_upload_error: 'There was an error uploading the files, please try again',
    no_signature_loaded: 'No signature has been uploaded yet',
    save_canvas: 'Save',
    clear: 'Clear',
    modify_signature: 'Modify',
    next: 'Next',
    previous: 'Back',
    upload_file: 'Upload File',
    unsupported_file_type: 'This file type is not allowed in this question',
    exceeded_max_number_files:
      'You have exceeded the maximum number of files allowed for this question',
    download_app_suggestion: 'Looking for a better and faster way to answer your forms?',
    confirm_section_change:
      "This section has a required question which you haven't answered correctly yet. Do you want to continue anyway?",
    load_more_data: 'Load more data',
    no_data_left: 'There is no data left to load',
    clear_all: 'Clear all',
    no_header_warning:
      'The creator of this question did not specify the list headers for the related lists.',
    more_answers_alert: 'Scroll to see more',
    subform_limit: 'Question group limit reached',
    subform_min: 'Please add the minimum number of answers',
    multi_option_placeholder: 'Select an option',
    image: 'Image',
    download: 'Download',
    multiple_options: 'Select multiple options',
    group: 'Group',
    start: 'Start',
    end: 'End',
    invalid_date: 'Invalid date',
    invalid_time: 'Invalid time',
    optionComments: {
      addNote: 'Add a note',
      removeNote: 'Remove note',
      writeComment: 'Write a comment',
      requiredNote: 'Comment required',
      addNoteMobile: 'Note',
      addImage: 'Attach Image',
      addImageMobile: 'Image',
      removeNoteMobile: 'Remove',
      requiredImage: 'Image required',
      deleteImageWarningTitle: 'Are you sure you want to delete this image?',
      deleteImageWarningSubTitle: "Once you delete it, you won't be able to get it back",
      cancel: 'Cancel',
      delete: 'Delete',
    },
    drag_drop_file: 'Drag and drop your files here',
    or_browse_for_file: 'or browse for them on your computer',
    missingRequiredAnswersAlert:
      'Oops! It seems there are still some questions left to answer. Please complete all the required questions to proceed.',
    totalPercentage: 'Total percentage',
    enterSampleTotal: 'Enter the sample total',
    forbidden_access:
      "It seems you don't have permission to edit this form. Request it to make changes",
    automated_signature_on_response:
      'This form has automatic signature configured. The request will be sent to the users assigned when the form is finished.',
    electronic_signature: 'Electronic signature',
    sign_now: 'Sign now',
    sign_later: 'Sign later',
    sign_modal_description: 'This form requires your signature, do you want to sign now?',
    signature_creation_failed: 'There was an error signing the form',
    saveSuccessAndRedirect: 'Answer saved correctly! Redirecting to your signatures...',
    saveAndSignSuccessAndRedirect:
      'Answer saved and signed correctly! Redirecting to your signatures...',
  },
  percentage: 'Percentage',
  date_fns: 'enGB',
  build_your_own_form: 'Build your own form with DataScope',
  back_to_taskForms: 'Return to form list',
  ok: 'OK',
  yes: 'Yes',
  no: 'No',
  insert_value: 'Insert a value',
  and: 'And',
  invalidEmail: 'Invalid Email',
  group: 'Group',
  folder: 'Folder',
  users: 'Users',
  forms: 'Forms',
  clearAll: 'Clear All',
  actions: 'Actions',
  select: 'Select',
  selectAll: 'Select All',
  form_builder: {
    formName: 'Form name',
    saveForm: 'Save',
    title: 'Title',
    titleDescription: 'Short title to identify the question. Appears when exporting data.',
    instruction: 'Description',
    instructionDescription:
      'Longer text to display in the app. Does not appear when exporting data (Optional).',
    questionType: 'Change question type',
    copy: 'Duplicate question',
    delete: 'Delete question',
    add: 'Add new question',
    advanced: 'Advanced Options',
    type: 'Type',
    date: 'Date',
    dateAndTime: 'Date and Time',
    time: 'Time',
    alternativeColumns: 'Alternative columns',
    addOption: 'Add option',
    dataListSource: 'Data list source',
    changeDataList: 'Change data list',
    listValues: 'List values',
    addListValue: 'Add list value',
    deleteAlternative: 'Delete alternative',
    required: 'Required',
    validateRutChile: 'RUT (Chile)',
    validateMail: 'E-mail',
    validateContainer: 'Container ID',
    validate: 'Validate',
    codeType: 'Change code type',
    disableGallery: 'Disable gallery',
    maxImagesPerItem: 'Max images per item',
    moveUp: 'Move up',
    moveDown: 'Move down',
    alternative: 'Alternative',
    snackBarShared: 'invitations sent',
    snackBar: 'Your form has been saved successfully!',
    newListData: 'New List Of Data',
    section: 'Add Section',
    sectionName: 'Unnamed section',
    duplicateSection: 'Duplicate section',
    deleteSection: 'Delete section',
    delSectionsQuestions: 'Delete section and questions',
    areYouSure: 'Are you sure?',
    cancel: 'Cancel',
    sectionAlertOne:
      'The section will be removed, but the questions will be added to the previous section.',
    sectionAlertTwo: 'The section will be deleted and also the questions inside it.',
    yesDelete: 'Yes, delete',
    questionAlert: 'This question will be permanently removed',
    copyName: 'Copy',
    nameAlert: 'The questions must have different names.',
    isRepeated: 'is repeated.',
    if: 'if',
    is: 'is',
    to: 'to',
    goTo: 'go to',
    sectionNameTag: 'Section name',
    skipQuestions: 'Skip questions',
    equal: 'equal',
    dif: 'different',
    empty: 'empty',
    notEmpty: 'not empty',
    skipOnlyMobile: 'Only applies for mobile use',
    disableSkip: 'This question type does not support skip logic.',
    next: 'next question',
    end: 'finish form',
    payingAlert:
      'This functionality is not available in the free plan, you must activate your plan in the configuration / billing.',
    showPercent: 'Show percent',
    showSamplingPercent: 'Show sampling percent',
    showPrice: 'Show price',
    showAvg: 'Show average',
    showFactor: 'Show factor',
    camQuality: 'Camera quality',
    high: 'High',
    mid: 'Mid',
    low: 'Low',
    recommended: 'recommended',
    photoAlert: 'synchronization might take longer',
    sectionNameAlert: 'There should be no sections with the same name. Please rename the section: ',
    formatType: 'Format types',
    any: 'Any',
    howToCreate: 'How to create forms',
    good: 'Good',
    regular: 'Regular',
    bad: 'Bad',
    newOption: 'New option',
    moveSection: 'Move section',
    oldShareForm: 'Share this form with a public link',
    shareForm: 'Form Link',
    shareEmail: 'Share by email',
    shareWhatsapp: 'Share by WhatsApp',
    shareWhatsappMessage: 'Share your form with a message by WhatsApp',
    shareWhatsappMessageApi: 'Hi, I invite you to complete the following DataScope form: ',
    formMadePublic: 'The public form link has been enabled',
    undoFormPublic: 'The public form link has been disabled',
    shareForm2: 'Share this form',
    shareFormTip:
      'Anyone, registered users and non-registered persons alike, will be able to fill out this web form if you share the public link with them. Their answers will be registered under the name of a generic user.',
    shareFormOn: 'Make this form public',
    shareFormOff: 'Remove public access',
    shareViaEmailTip: 'Include more emails with enter, space or comma',
    quantity: 'Maximum number of files',
    new: 'New!',
    warning: 'Temporarily only available for web forms',
    testYourForm1: 'Download our official App at ',
    testYourForm2: 'Later you can log in with these same credentials.',
    testYourForm3: 'Test your form on our app!',
    testYourForm4: 'Scan this QR code with your cellphone camera to view your form!',
    testYourForm5:
      'Scan this QR code with your cellphone camera to download our app and view your form!',
    copyLink: 'Copy link',
    visitForm: 'Visit',
    copiedLink: 'Copied!',
    accessYourForm: 'Access your form here',
    downloadApp: 'Download our app',
    ok: 'ok',
    and: 'and',
    greater: 'Greater than',
    greaterOrEqual: 'Greater than or equal to',
    less: 'Less than',
    lessOrEqual: 'Less than or equal to',
    between: 'Between',
    notBetween: 'Not between',
    number: 'Number',
    noValidation: 'No validation',
    validNumberWarning: 'Enter a valid number',
    validSetWarning: 'Enter a valid range',
    separator: 'Separator',
    separatorWarning: 'Enter a valid separator',
    relatedList: 'Related list',
    header: 'Header',
    headerPlaceholder: 'Country | City | Street',
    headerWarning: 'You must enter a header that contains the separator',
    headerDefault: 'List without header',
    deprecatedWarning: 'This type of question is no longer supported',
    combineAlert1: 'Questions can only be dragged into group of repeatable questions.',
    combineAlert2:
      'A group of repeatable questions cannot contain another group of repeatable questions.',
    combineAlertTitle: 'Error',
    emptySubform: 'Empty',
    emptySubformAlert:
      'Group of repeatable questions cannot be left empty, a question has been created automatically.',
    emptySubformAlertTitle: 'Warning',
    emptySubformTooltip: 'Remove all questions from group of repeatable questions',
    removeSubformQuestionTooltip: 'Remove question from group of repeatable questions',
    subformRemoveConfirm:
      'This question will be removed from this group of repeatable questions and added to the main form.',
    subformEmptyConfirm:
      'All questions will be removed from the group of repeatable questions and added to the main form.',
    confirm: 'Confirm',
    subformLimit: 'Limit repetitions',
    subformWarning: 'For more than 5 repetitions, upgrade your plan in configuration > billing',
    answer: 'Answer',
    includeComments: 'Comment',
    commentsFormLabel: 'Select the answers which require a comment.',
    textCommentBtnTooltip: 'Required Text',
    imageCommentBtnTooltip: 'Required Image',
    imageCommentBtnTooltipDisabled: "You can't add over a max of 500 images in total",
    share: 'Share',
    mobileTab: 'Mobile',
    webTab: 'Computer',
    max: 'Máximum',
    min: 'Mínimum',
    asCount: 'Counter',
    dataListSearchModalTitle: 'My lists',
    editLists: 'Edit lists',
    use: 'Use',
    usedInThisForm: 'Used in this form',
    otherLists: 'Other lists',
    multi_photo: 'Multiple photos',
    mobileOnlyAlert:
      'Available only in mobile forms. If this question is required, you can still answer the webform but this question will not appear.',
    availableAppWeb: 'Available on web and mobile app',
    imageDisabledByLimit:
      'The option to upload images has been disabled. It is not possible to upload more than 500 images in total',
    formBuilderBlockedAlertPart1:
      'You do not have permission to edit this form. If you want to create or edit forms ',
    formBuilderBlockedAlertPart2: 'upgrade your plan',
    formBuilderBlockedAlertPart3: ' now and unlock unlimited productivity',
    formNameExists: 'This form name already exists',
    saveError: 'There was an error saving the form',
  },
  help: {
    multi_option: 'Select an option from a list of items',
    select_metadata: 'Select an option from a dynamic data list',
    select_option_metadata: 'Select an option for each item on the list',
    select_option_metadata_comments: 'Select fixed alternatives for each item of a custom list',
    track_code: 'Provide the option to change or select the tracking code of each form',
    static_text: 'Allows adding fixed text as a form instruction',
    select_check_metadata: 'Select multiple items in a dynamic data list',
    select_number_metadata: 'Select a number for each item in the dynamic data list',
    select_text_metadata: 'Write text for each item in a dynamic data list',
    table: 'Create a group of questions that you can answer more than once',
  },
  types: {
    text: 'Text',
    date: 'Date/Time',
    number: 'Number',
    signature: 'Signature',
    barcode: 'Scanner',
    photo: 'Photo',
    multi_photo: 'Photo',
    multi_option: 'Single Choice',
    select_option_metadata: 'Checklist',
    select_option_metadata_comments: 'Checklist',
    select_check_metadata: 'Checkbox',
    select_number_metadata: 'Number Data',
    select_text_metadata: 'Text Data',
    static_text: 'Instruction',
    select_metadata: 'Single Option Data',
    table: 'Group of Repeatable Fields',
    attachment: 'Attach File',
    deprecated: 'Deprecated',
  },
  links: {
    appStore: 'https://itunes.apple.com/us/app/datascope-forms/id1132697668?l=en&ls=1&mt=8',
    playStore: 'https://app.datascope.io/apk',
    appLink: 'https://datascope.page.link/3N7P',
  },
  listManager: {
    dataListSource: 'Data source',
    name: 'Name',
    description: 'Description',
    code: 'Code',
    listItems: 'List Items',
    emptyList: 'This list has no items',
    attribute1: 'Attribute 1',
    attribute2: 'Attribute 2',
    listSubtitle: 'All these lists will be available when creating a new mobile form',
    seeAll: 'See all lists',
    seeObj: 'See list items',
    edit: 'Edit list',
    editObj: 'Edit item',
    delete: 'Delete list',
    deleteObj: 'Delete item',
    deleteObjectWarning: 'Are you sure you want to remove this item from the list?',
    deleteYes: 'Yes, delete',
    saveChanges: 'Save',
    cancel: 'Cancel',
    itemsOf: 'Items of:',
    addNewItem: '+ New item',
    page: 'Page ',
    of: ' of ',
    close: 'Close',
    legacy: 'Go to full list manager',
    locations: 'Locations',
  },
  tasksForms: {
    templates: 'Forms Templates',
    useTemplate: 'Use template',
    newVersion: 'Switch to new version',
    oldVersion: 'Switch to old version',
    uniq: 'Question name must be unique',
    title: 'Forms',
    preview: 'Preview',
    export: 'Export',
    export_help: 'You can copy this source code and paste into another form',
    import: 'Import',
    import_confirm: 'Are you sure you want to import this form?',
    import_success: 'Your form is being imported,  it can take some minutes',
    start_testing: "It's time to test your first form!",
    test_button: 'Test form',
    start_testing_desc:
      'Congrats you have a new form. Now you can start testing it on our mobile App.',
    import_error:
      "Failed to import the form. Please check if it's a valid form or is already imported.",
    clone: 'Duplicate',
    default_title: 'My Form',
    default_question: 'Question',
    drag_questions:
      "You don't have questions in your form yet, drag and drop the questions you want here",
    new: 'New form',
    new_ai: 'Form with AI',
    google_forms: 'Import Google Form',
    are_you_sure: 'Are you sure?',
    status: 'Status',
    active: 'Published',
    inactive: 'Unpublished',
    publish: 'Publish',
    unpublish: 'Unpublish',
    last_info: 'Add HTML at the end of PDF',
    current_logo: 'Current form Logo',
    form_logo: 'Form Logo',
    form_name: 'Name',
    actions: 'Configure',
    answer_form: 'Fill Out',
    edit_app: 'Modify APP',
    edit_pdf: 'PDF',
    edit_email: 'Email',
    edit_share: 'Share',
    delete: 'Delete',
    error_form: 'There was an error',
    success_form: 'Success',
    date: 'Date/Time',
    date_type: 'Date Type',
    date_type_date: 'Date',
    date_type_datetime: 'Datetime',
    date_type_time: 'Time',
    text: 'Text',
    number: 'Number',
    multi_option: 'Single Choice',
    signature: 'Signature',
    barcode: 'Scanner',
    photo: 'Photo',
    select_metadata: 'Single Option Data',
    select_option_metadata: 'Checklist',
    track_code: 'Tracking Code',
    static_text: 'Static Text',
    select_check_metadata: 'Checkbox',
    select_number_metadata: 'Number Data',
    select_text_metadata: 'Text Data',
    table: 'Group of Repeatable Fields',
    name: 'Question Title',
    description: 'Question Descriptions',
    label: 'Question Section Name',
    choices: 'Choices',
    choice: 'Choice',
    add_choice: 'Add Choice',
    list_values: 'List Values',
    add_list_value: 'Add List Value',
    add_question: 'Add Question',
    delete_choice: 'Delete',
    drop_question: 'Drop repeatable-questions here',
    metadata_type: 'Data List Source',
    create_metadata: 'Create New Data List Source',
    change_metadata: 'Change Data List Source',
    save_form: 'Save Form',
    version: 'version',
    no_name: 'No Name',
    no_questions: 'Must have at least one question',
    no_data: 'No forms available',
    blank_table: 'SubForm must to have',
    sub_questions: 'Sub Questions',
    disable_gallery: 'Disable Gallery',
    required: 'Required',
    back: 'Back',
    location: 'List of Locations',
    help_title: 'Need help creating your form?',
    help_instruction: 'Request form creation',
    help_description:
      '***Please explain the information you want to gather and attach a picture of the form you want to convert mobile',
    example_title_1: 'Build Your Own Digital Forms',
    example_description_1:
      "With the Form Builder you can create perfect forms. It's easy, intuitive, and powerful.",
    success_created: 'Task form was successfully created.',
    hide_logic: 'Skip question based on answer',
    show_price: 'Show price',
    show_avg: 'Show Average',
    show_factor: 'Show Multiplication',
    show_percent: 'Show percent',
    equal: 'Equal',
    not_equal: 'Not Equal',
    if: 'IF',
    is: 'IS',
    to: 'TO',
    go_to: 'GO TO',
    next_question: 'Next Question',
    finish_form: 'Finish Form',
    standard_pdf: 'Standard PDF',
    customized_pdf: 'Customized PDF',
    open_editor: 'Open PDF Editor',
    own_pdf: 'Build your own PDF Layout',
    need_your_pdf: 'Need your own PDF design?',
    pdf_description:
      'The most flexible PDF Builder to create your own documents with the data collected',
    folders: 'Folders',
    manage_folders: 'New folder',
    manage_states: 'Form states',
    view_all_folders: 'View All Folders',
    searchSend: 'Search',
    searchForm: 'Search forms...',
    custom_pdf: {
      step1: 'New template',
      step1_desc:
        'First step is to create your own template using the PDF editor. Is possible to use all the questions of the form. The last answer will be used as example.',
      step2: 'Activate template',
      step2_desc:
        'Next step is to activate the template when is ready. When you activate this, all the new PDFs are going to be generated with this format.',
    },
    help: {
      multi_option: 'Select alternatives into fixed options provided by you',
      select_metadata: 'Select one item of a custom list',
      select_option_metadata: 'Select fixed alternatives for each item of a custom list',
      track_code: 'Provide the option to change or select the tracking code of each form',
      static_text: 'Add static text (Form Instructions)',
      select_check_metadata: 'Check multiple items of a custom list',
      select_number_metadata: 'Select a number for multiple items of a custom list',
      select_text_metadata: 'Select a text for multiple items of a custom list',
      table:
        'Create loops for a specific set of questions that can be answered as many times as needed',
      description:
        'Longer text to show in the App. Does not appear when exporting data. (Optional)',
      title: 'Short title to identify the question. Appears when exporting data.',
    },
    share: {
      title: 'Generate Link Google Forms',
      link: 'Link:',
      edit_link: 'Edit Google Form',
      export: 'Export Google Form',
      sendgrid_template_id: 'd-6603328bc6e14ab284a0bee8ba373651',
      inactive_public_form: 'This form is no longer accessible using this link.',
      success_message: 'We have received your answer correctly',
      make_form_private: 'Remove public access to this form',
      make_form_private_notice:
        'This form is now private. Previously shared public links are now invalid.',
      make_form_public: 'Make this form public',
      make_form_public_notice:
        'This form is now public. You can now share it with people outside your organization!',
      share_via_email: 'Share this form with an email ',
      share_whatsapp_message: 'Hi, I invite you to complete the following DataScope form:',
      share_email_tooltip:
        'A public link, accessible to anybody, will be sent to those emails that are not associated with this account. A private use link will be sent to those emails that belong to registered users of this account.',
      share_public_link: 'Share this form with a public link',
      share_public_link_tooltip:
        'Anyone, registered users and non-registered persons alike, will be able to fill out this form if you share the public link with them. Their answers will be registered under the name of a generic user, webforms_user_',
      share: 'Share',
      copy: 'Copy',
      trigger: 'Trigger not installed. Limit of 20 Reached.',
      loading: 'Loading Form...',
      failed: 'Exporting Form Failed.',
      auth: "TIP: 'If you have problems, try logging out of all your Google Accounts (from google.com), and then login with the account you want the form to be exported to.'",
      add_all_permissions: 'Add this form to all users',
      permission_success:
        'Permission added to all users, you need to logout and login again in the App to refresh forms',
    },
    delete_form_confirm: 'Are you sure you want to delete the form?',
    delete_form_warning: 'Once deleted it cannot be recovered',
    delete_form_success: 'Form deleted successfully',
    delete_form_failure: 'Form deletion failed',
    clone_form_success: 'Form duplicated successfully',
    clone_form_failure: 'Form duplication failed',
    publish_form_success: 'Form published successfully',
    publish_form_failure: 'Form publication failed',
    unpublish_form_success: 'Form unpublished successfully',
    unpublish_form_failure: 'Form unpublishing failed',
    rename: 'Rename',
    rename_folder: 'Rename folder',
    folder_name: 'Folder name',
    new_name: 'New name',
    update: 'Update',
    cancel: 'Cancel',
    rename_success: 'Folder renamed successfully',
    rename_failure: 'Folder renaming failed',
    delete_folder_confirm: 'Are you sure you want to delete the folder ',
    delete_warning: 'Once the folder is deleted, all forms inside will be moved to "Forms" table',
    delete_folder_success: 'Folder deleted successfully',
    delete_folder_failure: 'Folder deletion failed',
    move_to_folder: 'Move to folder',
    remove_from_folder: 'Remove from folder',
    move: 'Move',
    warn_empty_folder: 'This action will delete the folder as it would leave it empty.',
    move_form_success: 'Form moved successfully',
    move_forms_success: 'Forms moved successfully',
    move_form_failure: 'Form movement failed',
    move_forms_failure: 'Form movement failed',
    create: 'Create',
    create_form_folder: 'Create form folder',
    create_folder_success: 'Folder created successfully',
    create_folder_failure: 'Folder creation failed',
    how_to_create_folder: 'How do you want to create your folder?',
    next: 'Next',
    form_selection: 'Form selection',
    no_forms_available: 'No forms available at this moment',
    forms: 'Forms',
    form: 'form',
    bulk_action: {
      publish: {
        confirm: 'Are you sure you want to publish',
        info: 'Once published, you can unpublish them again.',
        success: 'Forms published successfully',
        failure: 'Forms publication failed',
      },
      unpublish: {
        confirm: 'Are you sure you want to unpublish',
        info: 'Once unpublished, you can publish them again.',
        success: 'Forms unpublished successfully',
        failure: 'Forms unpublishing failed',
      },
      delete: {
        confirm: 'Are you sure you want to delete',
        info: 'Once deleted, they cannot be recovered.',
        success: 'Forms deleted successfully',
        failure: 'Forms deletion failed',
      },
    },
    configure_signature: 'Configure Electronic Signature',
    formResults: 'Results: ',
    formResultsOf: 'of',
    current_folder: 'Current folder:',
    formLimitReachedTooltip: 'You have reached the form limit. ',
    upgradePlanTooltipLink: 'Upgrade your subscription',
    upgradePlanTooltip: ' to create forms',
    importPermissionError: 'You do not have permission to import templates.',
    projectLimitReachedError:
      'There was an error importing the form. You have reached the maximum number of allowed forms.',
  },
  mobileUsers: {
    users: 'Users',
    new: 'New User',
    import: 'Import',
    import_users: 'Import Users',
    how_import: 'How to Import users?',
    bulk_actions: 'Bulk Actions',
    add_users_to_group: 'Add users to group',
    deactivate_users: 'Deactivate users',
    add: 'Add',
    add_to_group: 'Add to group',
    selectAGroup: 'Select a group',
    identifier_help: 'Needed to sign in in the mobile and web app',
    advanced: 'Advanced Config',
    location_perm: 'Location Permissions',
    forms_perm: 'Forms Permissions',
    activated: 'Activated',
    you: 'You',
    deactivated: 'Deactivated',
    modifications_history: 'History',
    duplicate_user:
      'A deleted User with same email already exists. Please reactivate that user instead',
    old_password_required: 'not valid. For changing admin password confirmation required.',
    group_users: 'User Groups',
    group_new: 'New Group',
    noGroups: 'You have no user groups',
    infoNotice: "Users will only have access to their groups' forms and answers",
    delete_group: 'Delete group',
    cancel: 'Cancel',
    delete: 'Delete',
    rename: 'Rename',
    rename_group: 'Rename group',
    group_name: 'Group Name',
    new_name: 'New Name',
    update: 'Update',
    confirm_group_delete: 'Are you sure you want to delete user group ',
    confirm_group_delete2:
      'Once it has been deleted, users left without a group will go back to their individually assigned permissions.',
    update_groups_success: 'Changes saved successfully',
    update_groups_error: 'An error occurred while saving changes',
    userInfo: 'User Information',
    userGroupsHelp: 'This user belongs to the following groups:',
    manageGroupModalTitle: 'Permissions in',
    searchForm: 'Search form...',
    searchUser: 'Search user...',
    groupUpdateSuccess: 'Group updated successfully',
    permissions_title: 'Permissions',
    forms_title: 'Forms',
    group_name_exists: 'Group name already exists',
    edit_group_permissions: 'Form Permissions',
    edit_groups: 'Assign to a group',
    save: 'Save',
    editGroupsInfo:
      'Users assigned to one or more groups will be granted permissions to access the forms of those groups. Consequently, they will not be able to have individually assigned forms at the same time.',
    modifiedGroup: 'Modified Group',
    modificationAuthor: 'Modified by',
    modificationDate: 'Date',
    modification: 'Modification',
    added: 'Added',
    removed: 'Removed',
    group: 'Group',
    createdGroup: 'Created Group',
    deletedGroup: 'Deleted Group',
    renamedGroup: 'Renamed Group',
    emptyHistory: 'No history available',
    previousName: 'Previous Name',
    removeFromGroup: 'Remove from group',
    remove: 'Remove',
    removeUsersConfirm: 'Are you sure you want to remove',
    removeUsersConfirm2: 'user(s) from group',
    onceUsersRemoved:
      'Once users are removed, they will be kept in other groups where they belong or stay as users without group.',
    noUsersInGroup: "You haven't added any users to this group yet",
    searchGroups: 'Search for groups...',
    pendingConfirmationTitle: 'Pending confirmation',
    pendingConfirmationSubtitle: 'Share this link only with this user',
    copy: 'Copy',
    linkCopied: 'Link copied!',
    resendInvitation: 'Resend',
    deleteGroupPendingSignatureError: 'Group has pending signatures',
    invite_user: {
      title: 'Invite Users',
      placeholder: 'example@example.com',
      help: 'If you want to change user permissions, click on configure permissions',
      btn: 'Invite',
      config: 'Configure permissions',
      successSingular: 'Invitation successfully sent',
      successPlural: 'Invitations successfully sent',
      error: 'Failed to invite users, please check that the emails are valid and not used before',
      emailFormatError: 'Wrong email format, please follow example@example.com',
      emails: 'Emails',
    },
    permissions: {
      answers: 'Show Form Answers',
      evaluate: 'Allow to change status of form answers',
      chat: 'Chat',
      tasks: 'Answer Forms',
      assigned: 'View Assigned Forms',
      users: 'Show and Create Users',
      export: 'Export Data',
      metadata: 'Create List of Custom Data',
      modify: 'Modify Form Answers',
      edit_forms: 'Edit Forms',
      evaluate_delete: 'Delete/Recover Form Answers',
    },
    roles: {
      customized: 'Customized',
      administrator: 'Administrator',
      superadmin: 'Account Owner',
      supervisor: 'Supervisor',
      editing_operator: 'Editing-enabled operator',
      operator: 'Operator',
    },
    table: {
      name: 'Name',
      user: 'User',
      role: 'Role',
      lastConnection: 'Last Connection',
      noLastConnection: 'Last Connection not available',
      actions: 'Actions',
      configure: 'Configure',
      edit: 'Edit',
      deactivate: 'Deactivate',
      activate: 'Activate',
      no_data: 'No users available',
      groups: 'Groups',
      viewMore: 'View more',
      noForms: 'This user group has no assigned forms',
      not_confirmed: 'Confirmation pending',
    },
    logs: {
      user: 'Modified User',
      author: 'Modification Author',
      date: 'Date',
      modification: 'Modification',
      activated: 'Activated',
      deactivated: 'Deactivated',
    },
    deactivation: {
      cancel: 'Cancel',
      confirmationTitle: 'Are you sure you want to deactivate this user?',
      confirmationMessage:
        'Data will not be lost. You can activate the user again in the Deactivated section.',
      bulkConfirmationTitle: 'Are you sure you want to deactivate',
      bulkConfirmationMessage:
        'Data will not be lost. You can activate them again in the Deactivated section.',
      success: 'User(s) deactivated successfully',
      error: 'There was an error deactivating the user(s)',
    },
    createUser: {
      information: {
        title: 'Information',
        name: 'Name',
        lastName: 'Last Name',
        email: 'Email',
        example: 'example@example.com',
        password: 'Password',
        passwordHelper: 'Password must be at least 6 characters long',
        phone: 'Telephone',
      },
      group: {
        title: 'Group',
        selectHelper: 'Select an existing group (you can choose more than one)',
        noGroup: 'Without group',
        newGroup: 'New group',
        newGroupHelper: 'Write the name of the group',
        newGroupSuccess: 'New Group created successfully!',
        newGroupError: 'There was an error creating the new group',
        configuration: 'You must configure form permissions for this group',
        formsHelp: 'Forms that this user will have access to:',
      },
      forms: {
        title: 'Forms',
        allForms: 'Forms',
        addForm: 'Add form',
        formsHelper: 'The user will only see the forms (and answers) you select. ',
        formsHelperDisabled: 'The user will only see these forms (and answers). ',
        formsGroupHelper:
          'The forms and places to which this user will have access are those belonging to the previously chosen groups.',
        seeMore: 'see more',
        groupsAlert: 'You cannot add forms because you assigned or created a group previously',
        back: 'Back',
        folders: 'Folders',
        close: 'Close',
        formHasPendingSignature:
          "This user has pending signatures. You won't be able to modify the permissions until they are responded to or deleted.",
        massSelectionBlockedByPendingSignature:
          'Mass selection is disabled due to pending signatures',
        foldersBlockedByPendingSignature:
          'Folder selection is disabled due to pending signatures. Open the folder to select forms.',
      },
      permissions: {
        title: 'Permissions',
        editingMyself:
          'You cannot change your own permissions. Have someone else do it if you want to change them.',
        basic: {
          title: 'Basic',
          viewFormAnswers: 'View Form Answers',
          chat: 'Chat',
          submitFormAnswers: 'Answer Forms',
          viewAssignedForms: 'View Assigned Forms',
        },
        advanced: {
          title: 'Advanced',
          changeFormStatus: 'Change status of forms',
          createMetadata: 'Create Lists of Custom Data',
          editFormAnswers: 'Edit Form Answers',
          editForms: 'Edit Forms',
          evaluateDelete: 'Delete and recover form responses',
        },
        admin: {
          title: 'Administration',
          viewAndCreateUsers: 'View and Create Users',
          exportData: 'Export Data',
        },
        editFormsTooltip1: 'This user does not have permission to edit forms. ',
        editFormsTooltipLink: 'Upgrade your subscription',
        editFormsTooltip2: ' and unlock full productivity for your team!',
      },
      locations: {
        title: 'Locations (Optional)',
        helper:
          'When this option is activated, the user will only see the forms associated to the selected locations',
        locations: 'locations',
        andOthers: ', and others...',
      },
      signature: {
        name: 'Full Name',
        companyName: 'Company Name',
        userIdNumber: {
          default: 'National ID Number',
          CL: 'RUT',
          PE: 'DNI',
          MX: 'CURP',
          CO: 'CC',
        },
        country: 'Country',
        selectCountry: 'Select a country',
        userEmail: 'Personal Email',
        legalRepresentative: 'Legal Representative',
        legalRepresentativeIdNumber: {
          default: 'Legal Representative Tax ID Number',
          CL: 'Legal Representative RUT',
          PE: 'Legal Representative RUC',
          MX: 'Legal Representative RFC',
          CO: 'Legal Representative CC',
        },
        companyIdNumber: {
          default: 'Company Tax ID Number',
          CL: 'Company RUT',
          PE: 'Company RUC',
          MX: 'Company RFC',
          CO: 'Company NIT',
        },
        address: 'Address',
        companyEmailError: "You must use an email that's different from the user's email",
        userIdNumberError: {
          default: 'Invalid Tax ID Number',
          CL: 'Invalid RUT',
          PE: 'Invalid RUC',
          MX: 'Invalid RFC',
          CO: 'Invalid CC',
        },
        userIdNumberSuccess: {
          default: 'Your Tax ID Number has been verified',
          CL: 'Your RUT has been verified',
          PE: 'Your RUC has been verified',
          MX: 'Your RFC has been verified',
          CO: 'Your CC has been verified',
        },
        companyIdNumberError: {
          default: 'Invalid Tax ID Number',
          CL: 'Invalid RUT',
          PE: 'Invalid RUC',
          MX: 'Invalid RFC',
          CO: 'Invalid NIT',
        },
        companyIdNumberSuccess: {
          default: 'Your Tax ID Number has been verified',
          CL: 'Your RUT has been verified',
          PE: 'Your RUC has been verified',
          MX: 'Your RFC has been verified',
          CO: 'Your NIT has been verified',
        },
      },
      cancel: 'Cancel',
      save: 'Save',
      success: 'Mobile user created successfully!',
      error: 'There was an error creating the new mobile user',
    },
    editUser: {
      information: {
        oldPassword: 'Current Password',
        newPassword: 'New Password',
        passwordChange: 'Change password',
        error: 'There was an error updating the user',
        success: 'Mobile user updated successfully!',
      },
    },
    createGroup: {
      button: 'Create group',
      title: 'Create user group',
      chooseHeader: 'How do you want to create the group?',
      name: 'Group name',
      nameHelper: 'Group name',
      userSelection: 'User selection',
      formSelection: 'Form selection',
      importExcelNote: "*You'll need to assign permissions to forms later",
      warning:
        'Users assigned to a group will access the forms of that group and cannot have individually assigned forms at the same time.',
    },
    setProfile: {
      completeProfile: 'Complete your profile',
      completeProfileDescription: 'Please enter the following information to enter DataScope',
      newPassword: 'New password',
      name: 'Name',
      lastName: 'Last name',
      phone: 'Phone',
      save: 'Save',
      updateSuccess: 'Profile updated successfully',
      updateError: 'There was an error updating the profile',
      identifier: 'Identifier',
    },
  },
  login: {
    greeting: 'Hi again!',
    loginHelper: 'To continue, enter your credentials',
    googleLogin: 'Login with Google',
    linkedinLogin: 'Login with Linkedin',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot your password?',
    login: 'Log in',
    noAccount: "Don't have an account?",
    signUp: 'Register here',
    or: 'or',
  },
  templates: {
    total_templates: 'Total Form Templates: ',
    see_all: 'See all',
    showing_results: 'Showing results for',
    categories: 'Categories',
    in: 'in',
    useTemplate: 'Use template',
    noResults: 'We have not found what you were looking for, but we would love to find a solution!',
    contactSupport: 'Tell us about the form you need via chat',
    next: 'Next',
    back: 'Back',
    useCase: 'Can be used for:',
    createdBy: 'Created by',
    anonymousUser: 'Anonymous user',
    preview: 'Preview',
    editableTemplate: 'This template can be modified after importing it.',
    allTemplates: 'All Templates',
    noDescription: 'No description',
    bannerSlogan: 'Collect all your data from any mobile device',
    templateView: 'Template View',
    seeLess: 'See less',
    seeMore: 'See more',
  },
  navbar: {
    features: 'Features',
    solutions: 'Solutions',
    work_orders: 'Work Orders',
    safety: 'Safety',
    inspections: 'Inspections',
    quality: 'Quality',
    resources: 'Resources',
    blog: 'Blog',
    ds_academy: 'DataScope Academy',
    digitize: 'Digitize your form',
    roi: 'ROI Calculator',
    pricing: 'Pricing',
    sales: 'Talk to Sales',
    wapp: '',
    call: '',
    demo: '',
    sign_in: 'Sign In',
    sign_up: 'Sign up Free',
    log_out: 'Log Out',
    paths: {
      work_orders: 'work-orders',
      safety: 'safety',
      inspections: 'inspections',
      quality: 'quality',
      blog: 'blog',
      ds_academy: 'digitalize-your-operations-with-datascope-2',
      digitize: 'upload-form',
      roi: 'roi',
    },
  },
  uiComponents: {
    search: 'Search',
    showingResultsFor: 'Showing search results for',
    found: 'found',
    cancel: 'Cancel',
    selected: 'selected',
    confirm: 'Confirm',
    close: 'Close',
    chooseMethodDialog: {
      fromDatascope: 'From DataScope',
      importExcel: 'Import Excel',
    },
    signature: {
      title: 'Electronic Signature',
      alert:
        'Complete the following fields to create your signature. Name and last name in the information area are required to create your signature.',
      accountAlert: 'Remember to click "Save Signature".',
      saveSignature: 'Save Signature',
      completeSignatureWarning: 'You must fill out all the required fields to save your signature',
      signatureRequiredFieldError: 'This field is required to create the electronic signature',
      updateSuccess: 'Signature saved successfully!',
      updateError: 'There was an error saving the signature',
      invalidEmailError: 'Invalid email',
      emailExistsError: 'Email address is already in use',
      validatingRut: 'Validating RUT...',
      emailErrors: {
        REGEX_INVALID: 'Invalid email',
        IN_USE: 'Email address is already in use',
      },
    },
  },
  formEmailBuilder: {
    message: 'Message',
    subject: 'Subject',
    senderName: "Sender's Name",
    addFooter: 'Add Signature',
    footerTitle: 'Signature',
    notification: 'Notification',
    autoNotify: 'Notify fixed emails',
    save: 'Save',
    cancel: 'Cancel',
    linkName: 'PDF Form Download Link',
    linkNamePlaceholder: 'Enter the name you want the link to have',
    linkNameHelp:
      'The name you configure will be the one in the download link that will arrive in the email.',
    saveSuccess: 'Configurations saved successfully',
    saveError: 'There was an error saving the configurations',
    autoGeneratePdf: 'Generate PDF Automatically',
  },
  accountConfig: {
    information: {
      title: 'Account Configuration',
      name: 'Company Name',
      description: 'Company Description',
      email: 'Company Email',
      phone: 'Company Phone',
      address: 'Company Address',
      timezone: 'Timezone',
      locale: 'Language',
      logo: 'Logo',
      uploadLogo: 'Image',
      logoDescription:
        'Only .PNG or .JPG files. Image size should be 400x400px with a maximum file size of 2MB.',
      logoSizeError: 'The maximum file size allowed is 2MB.',
      saveSuccess: 'Account information updated successfully!',
      saveError: 'There was an error updating the account information',
      pdfAlert: 'The following information will be displayed in the header of the standard PDF.',
      confirmDeleteLogo: 'Are you sure you want to delete the logo?',
    },
    advancedConfig: {
      title: 'Advanced Configuration',
      activateSupportUser: 'Grant Access to DataScope Support User',
      securityConfig: 'Security Configuration',
      deactivateAccount: 'Disable Account',
      confirmTitle: 'Are you sure you want to disable your account?',
      confirmMessage:
        'This action cannot be undone. All your users and data will also be deactivated.',
    },
  },
  notifications: {
    title: 'Notifications',
    searchPlaceholder: 'Search by form name...',
    viewFormAnswer: 'View Form Answer',
    viewExcel: 'View Excel',
    viewPdf: 'View PDF',
    tableHeaders: {
      date: 'Date',
      type: 'Type',
      form: 'Form',
      formCode: 'Code',
      user: 'User',
      location: 'Location',
      actions: 'Actions',
    },
    formExport: 'Form Export',
    formAnswer: 'Form Answer',
    filters: {
      title: 'Filters',
      filterByType: 'By type',
      filterByLocation: 'By location',
      filterByUser: 'By user',
      filterByDateRange: 'By date range',
      startDate: 'Start',
      endDate: 'End',
      invalidDate: 'Invalid date',
      invalidDateRange: 'Invalid date range',
      to: 'to',
      eraseAll: 'Erase all',
      apply: 'Apply',
      formAnswers: 'Form Answers',
      formExports: 'Exports',
      clear: 'Clear',
      locationPlaceholder: 'Select a location',
      userPlaceholder: 'Select a user',
      showingResultsFor: 'Showing results for',
    },
    noNotifications: 'No notifications available',
    noResults: 'No results found with the selected filters',
    limitReached:
      'Your account reached the limit. You must activate your account to enable this feature.',
  },
  formSignatureBuilder: {
    signatureTitle: 'E-Signature activation',
    checkboxSignatureActivate: 'Activate electronic signature',
    settingsTitle: 'General settings',
    save: 'Save',
    cancel: 'Cancel',
    saveSuccess: 'Settings saved successfully',
    saveError: 'An error occurred while saving the settings',
    saveErrorReload:
      'An error occurred while saving the settings. Please reload the page and try again',
    accountSignatureConfigError: "The account's electronic signature configuration is missing",
    generalSettings: 'General Settings',
    mandatorySignature:
      'Mandatory signature (All configured users must respond to the request, either by signing or rejecting it)',
    optionalSignature: 'Optional signature (One of the configured signers must sign)',
    signersSettings: 'Signers Settings',
    addUsers: 'Add users',
    authorMustSign: 'The user submitting a response must also sign',
    assignUsers: 'Assign users',
    assignOwner: 'Assign responsible',
    searchPlaceholder: 'Search user or group',
    usersTab: 'Users',
    groupsTab: 'Groups',
    addButton: 'Add',
    externalUsers: 'External users',
    externalUsersDescription: 'External users will be able to view and sign the form',
    selectResponsible: 'Select the request responsible',
    addResponsible: 'Add responsible',
    selectUsersToSign: 'Select the users who must sign the document',
    selectResponsibleTooltip:
      'The selected user will be able to track the status of all requests and edit them, but will not be able to sign. To sign, they must be added to the signers list',
    simultaneousSign: 'Simultaneous Sign',
    sequentialSign: 'Signature sequence',
    simultaneousSignInfo: 'The request will be sent simultaneously to all users',
    sequentialSignInfo: 'The request will be sent in the order you establish',
    simultaneousEmptyState: 'Drag users here to remove them from the sequence',
    sequentialEmptyState: 'Drag users here to create a signature sequence',
    sequentialWarningInfo: 'You must add all users to generate a request - {value} users',
    sequentialSignSuccess: 'Sequence complete',
    automatedSignature: 'Activate automatic signature request',
    locationUser: 'Location emails',
    locationUserTooltip:
      'By selecting "Location Connection", the request will only be sent to the first associated place.',
    locationUserViewEmails: 'View emails',
    locationConnection: 'Location Connection',
    locationConnectionTooltip1:
      'If the form includes a question to select a location, a request will be sent to the emails configured there',
    locationConnectionTooltip2:
      'If you assign a task with this form and choose a location, the emails configured there will receive a request when the task is completed',
    optionalSignatureOrderDisabled:
      'This option is not available for optional signature configuration',
    author: "Response's author",
    authorMustSignFirst:
      'This position is fixed within the sequence, but you can move it to Simultaneous Signature',
    deleteSigner: 'Delete user',
    unGroupSigner: 'Ungroup',
    authorUserTooltip:
      'If the response author is already part of the sequence, their original position will be maintained instead of the one assigned for the author',
  },
  pricing: {
    title: 'Perfect for exploring DataScope features',
    basic: {
      title: 'Basic',
      subtitle: 'Limited users, 60 form answers per month, answer storage for 30 days',
      features: [
        'Unlimited users',
        '60 form responses/month',
        'Data stored for 30 days',
        'Basic form builder',
        'Task assigner',
        'Standard dashboard',
        'Standard photo resolution',
        'Offline functionality',
      ],
      buttonText: 'Actual plan',
    },
    standard: {
      title: 'Standard',
      subtitle: 'Ideal for teams taking their first steps in process digitization',
      features: [
        'Everything included in the Basic Plan +',
        'Unlimited form responses',
        'Data stored forever',
        'Advanced form builder',
        'Custom PDF report generator',
        'Editable user permissions',
        'Data integration with other platforms',
        'In-app user chat',
        'Email/phone/chat support',
      ],
    },
    premium: {
      title: 'Premium',
      subtitle: 'For businesses requiring a comprehensive, real-time view of all their operations',
      features: [
        'Everything included in the Standard Plan +',
        'Advanced dashboards',
        'API support',
        'Configurable photo resolution',
        'Assigned account executive',
        'Workflow support',
      ],
      buttonText: 'Change to premium',
    },
    enterprise: {
      title: 'Enterprise',
      subtitle: 'For large companies seeking a personalized solution and support',
      features: [
        'Everything included in the Premium Plan +',
        'Custom implementation',
        '24/7 preferential support',
      ],
      buttonText: 'Talk to Sales',
    },
    features: 'Features',
    start: 'Start',
    currentPlan: 'Current plan',
    mostPopular: 'Most popular',
    free: 'Free',
    monthlyPerUser: 'monthly per user',
    users: 'users',
    standardBold: 'Standard',
    off: 'off',
    finishesIn: 'Finishes in',
    ofDiscount: 'discount.',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    salesLink: 'https://meetings.hubspot.com/rocio-sanchez',
    activePayment: {
      demoSubscription: 'Paid subscription',
      demoSubscriptionDescription: 'Your current plan includes 30 users.',
      demoSubscriptionDescription1: ' user(s).',
      demoSubscriptionDescription2: 'You can invite more by clicking the button below.',
      inviteUsers: 'Invite users',
      demoBannerDescription: 'You have no pending payments!',
      demoBannerDescription2:
        "If you have any questions about your subscription, don't hesitate to contact our team.",
      moreInfo: 'More information',
      viewBillingDetails: 'View billing details',
      changeUsers: 'Change number of users',
      modify: 'Modificar',
      monthlyValue: 'Monthly value',
      annualValue: 'Annual value',
      total: 'Total',
      valuesMayDiffer: '*Values may differ based on your local currency.',
      modifyUsersSuccess: 'Subscription updated successfully!',
      modifyUsersError:
        'Encontramos problemas ao atualizar sua assinatura. Por favor, tente novamente ou entre em contato com nossa equipe de vendas.',
      confirmModifyUsers: 'Are you sure you want to change the number of users?',
      confirmModifyUsersDescription:
        'This action will change the value of your subscription and any difference will be charged immediately.',
      numberOfUsers: 'Number of users',
      paymentDetails: 'Payment details',
    },
  },
  findings: {
    title: 'Issues',
    new: 'New',
    all: 'All',
    createdBy: 'Created by',
    expires: 'Expires',
    type: 'Type',
    description: 'Description',
    searchPlaceholder: 'Search by name, description, author or ID',
    createdIn: 'Created in',
    originQuestion: 'Question',
    note: 'Note',
    viewForm: 'View form',
    createYourFirstFinding: 'Create your first issue',
    emptyState: 'Add a Checklist question to a new or existing form to start creating issues',
    goToForms: 'Go to Forms',
    detailEmptyState: 'Select an issue to view the history',
    findingDetail: 'Issue detail',
    idCopied: 'ID copied!',
    delete: 'Delete',
    deletedSuccessfully: 'Issue deleted successfully',
    tabs: {
      inProgress: 'In Progress',
      closed: 'Closed',
    },
    options: {
      viewDetails: 'View details',
      addImage: 'Add image',
      sendFormAnswers: {
        menu: 'Send form answers',
        title: 'Form answers',
        button: 'Send to issue',
        cancel: 'Cancel',
        description: 'Select the response you want to send to the issue',
        searchPlaceholder: 'Search form',
        answeredBy: 'Answered by',
        updatedAt: 'Updated at',
        noResults: 'No answers found',
        seeAnswer: 'See answer',
        noAnswers: "You don't have any answers at the moment",
      },
      sendForms: {
        button: 'Send',
        menu: 'Send form',
        sync: 'Syncing',
      },
      share: {
        menu: 'Share link',
        title: 'Share link',
        text: 'Anyone with the link can access the issue',
        activate: 'Enable',
        deactivate: 'Disable',
        loginTitle: 'Welcome to DataScope!',
        loginDescription: 'To continue, please enter your name and email address',
        loginNamePlaceholder: 'Your Name',
        loginEmailPlaceholder: 'Email address',
      },
    },
    createFinding: {
      title: 'Create Issue',
      requiredInformation: 'Required Information',
      optionalInformation: 'Optional Information',
      cancel: 'Cancel',
      create: 'Create',
      name: 'Name',
      type: 'Issue Type',
      description: 'Description',
      assignees: 'Assignee(s)',
      dueDate: 'Due Date',
      location: 'Location',
      inviteParticipants: 'Invite Participants',
      findingSuccess: 'Issue created successfully',
      findingError: 'Error creating issue',
      findingCreated: 'Issue created',
      editInFindings: "Go to the 'Issues' section to make changes.",
    },
    editFinding: {
      title: 'Edit Issue',
      update: 'Update',
      findingSuccess: 'Issue updated successfully',
      findingError: 'Error updating issue',
    },
    closeFinding: {
      title: 'Close issue',
      instruction: 'Complete the following information to close the issue',
      closingNoteLabel: 'In what state did you close the issue?',
      cancel: 'Cancel',
      confirm: 'Close issue',
    },
    status: {
      title: 'Status',
      open: 'Open',
      in_progress: 'In Progress',
      paused: 'Paused',
      closed: 'Closed',
    },
    priority: {
      title: 'Priority',
      low: 'Low',
      medium: 'Medium',
      high: 'High',
      critical: 'Critical',
    },
    deleteWarningConfirm: 'Are you sure you want to delete the issue?',
    deleteWarningConfirmDescription: 'This action cannot be undone.',
    chat: {
      events: {
        finding_created: 'Issue created',
        finding_closed: 'closed the issue',
        finding_closed_expired: 'closed the issue after the specified deadline',
        status: 'updated the status to',
        priority: 'updated the priority to',
        description: 'updated the description of the issue to',
        expiration_date: 'updated the due date to',
        name: 'updated the name of the issue to',
        location: 'updated the location to',
        added_assignees: 'added',
        removed_assignees: 'removed',
        assignees_singular: 'as assignee',
        assignees_plural: 'as assignees',
        added_invitees: 'added',
        removed_invitees: 'removed',
        invitees_singular: 'as invitee',
        invitees_plural: 'as invitees',
      },
      image: {
        error: 'Error',
        sync: 'Image Syncing',
      },
    },
    export: {
      pdf: {
        title: 'Export PDF',
        tooltip: 'Export PDF',
        info: 'You will export all the information included in the issue. Once exported, the download link will be sent to your email.',
        exportSuccess: 'Issue PDF exported successfully',
        exportError: 'Error exporting issue PDF',
        buttonTitle: 'PDF',
      },
      all: {
        title: 'Export all',
        tooltip: 'Export to Excel',
        info: 'You will export the following variables in Excel format: Name, description, responsible parties, participants, expiration, priority, and location. Once exported, the download link will be sent to your email.',
        exportSuccess: 'Issues data exported successfully',
        exportError: 'Error exporting issues',
        buttonTitle: 'Excel',
      },
      emailInputLabel: 'Email',
      cancel: 'Cancel',
      confirm: 'Export',
    },
    landing: {
      headerTitle: 'Resolve issues quickly, with traceability from start to finish',
      infoTitle1:
        'You will be able to notify the right people to generate an ideal workflow and track the resolution of the issue.',
      actionButton: 'Start free trial',
      infoDemoTime: 'Try Issues free for 30 days',
      infoDisclaimer:
        "Once the free trial ends, you'll still be able to create issues, but you won't be able to access them",
      headerImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-gif-en.gif',
      featuresTitle: 'Everything you need',
      feature1Description: 'Create issues customised by priority',
      feature2Description: 'Keep a record of issue updates',
      feature3Description: 'Perform inspections and link an issue',
      feature1Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature1-en.png',
      feature2Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature2-en.png',
      feature3Image:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-feature3-en.png',
      footerImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-notification-en.png',
      infoTitle2: 'Make sure not to miss any details while tracking the evolution of the issue',
      featuresMobileImage:
        'https://data-scope.s3.us-west-2.amazonaws.com/assets/findings/findings-landing-features-responsive-en.png',
    },
    demoExpired: {
      title: 'Your free trial has ended!',
      description:
        'To access your data, we invite you to purchase the Issues module. You can access it by clicking on the button',
      actionButton: 'Activate Issues',
    },
    suscriptionCancelled: {
      title: 'Your subscription has expired',
      description:
        'To access your data, we invite you to pay your subscription to the Issues module.',
    },
    adminAlert:
      'You will be able to see all issues, but to receive updates you must assign yourself as assignee or participant.',
    configure: 'Settings',
    config: {
      findingTypes: 'Issue Types',
      createType: 'Create Type',
      editType: 'Edit',
      deleteType: 'Delete',
      deleteTypeDisabled: 'You cannot be left without issue types',
    },
    integrations: {
      title: 'Issues Integration',
      button: 'Integrations',
      gsheetsMenuName: 'Google Sheets',
      gsheetsIntegrationLabel: 'Go to Google Sheets',
      infoTextPart1: 'To ensure proper, private, and secure integration, share your document with',
      infoTextPart2: 'editor',
      infoTextPart3: 'permissions only to',
      inputLabel: 'New Link',
      inputPlaceholder: 'Enter the link of the document to integrate',
      save: 'Save',
      cancel: 'Cancel',
      copyUrl: 'URL copied!',
      created:
        'Integration successfully configured! Please wait a few seconds for the changes to take effect',
      saved: 'Changes saved successfully!',
      savingError: 'Error configuring the integration',
      confirmation: 'Do you want to save the changes made before exiting?',
      webhooks: 'Webhooks',
      webhookUrl: 'Webhook URL',
      infoWebhooksTextPart1:
        'Automate your workflows by allowing DataScope to trigger processes on external platforms.',
      infoWebhooksTextPart2: 'Keep everything in sync effortlessly.',
    },
    findingTypes: {
      noType: 'Other',
      createTitle: 'Create Issue Type',
      editTitle: 'Edit Issue Type',
      name: 'Name',
      assignees: 'Assignees',
      assigneesCaption: 'If you do not assign a responsible party, you can do so in the app',
      location: 'Location',
      locationCaption: 'If you do not assign a location, you can do so in the app',
      save: 'Save',
      update: 'Update',
      cancel: 'Cancel',
      dueDateAndPriority: 'Due Date and Priority',
      priorityInformationBanner:
        'You can select a priority as default (optional). The creator of the finding will not be able to change it but will see its description.',
      dueDate: 'Due Date',
      dueDateCaption: 'This configuration will start counting from the creation of the finding',
      dueDateTypes: {
        days: 'Days',
        hours: 'Hours',
      },
      priority: 'Priority',
      description: 'Description',
      descriptionPlaceholder: 'Write the description or instruction of the priority',
      additionalInformation: 'Additional Information (Optional)',
      additionalInformationBanner:
        'Assign predefined forms for the participants in this issue to complete',
      typeCreatedSuccess: 'Issue type created successfully',
      typeCreatedError: 'Error creating issue type',
      typeDeletedSuccess: 'Issue type deleted successfully',
      typeDeletedError: 'Error deleting issue type',
      typeUpdatedSuccess: 'Issue type updated successfully',
      typeUpdatedError: 'Error updating issue type',
      deleteTypeAlertTitle: 'Are you sure you want to delete this type of issue?',
      deleteTypeAlertMessage:
        'If you delete it, you will not be able to recover it, but the information related to it will remain on the platform',
    },
  },
  commonComponents: {
    formSelector: {
      title: 'Forms',
      addForm: 'Add form',
      searchPlaceholder: 'Search form, folder...',
      forms: 'Forms',
      folders: 'Folders',
      save: 'Save',
      cancel: 'Cancel',
      see: 'See',
      more: 'more',
      close: 'Close',
      maxFormsSelected: 'You have reached the maximum number of forms selected',
    },
    confirmActionAlert: {
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    formDropdownSelector: {
      loading: 'Loading...',
      noFormsFound: 'No forms found',
    },
    dateRangePicker: {
      last7Days: 'Last 7 days',
      last14Days: 'Last 14 days',
      last30Days: 'Last 30 days',
      last60Days: 'Last 60 days',
      last90Days: 'Last 90 days',
      thisYear: 'This year',
    },
    locationSelector: {
      loading: 'Loading...',
      noLocationsFound: 'No locations found',
    },
  },
  webHooks: {
    title: 'WebHooks',
    new: {
      title: 'Create new WebHook',
      token: 'Token:',
      expires_in: 'Expires in:',
      expires_at: 'Expires at:',
      form: 'Form',
      select_form: 'Select a form',
      select_all_forms: 'All Forms',
      url: 'Connection URL',
      example_url: 'http://www.yourserver.com/service',
      send_modifications: 'Send modifications',
      oauth_checkbox: 'Authenticate with OAuth2.0',
      save: 'Save',
      back: 'Back to WebHooks',
      configure_oauth: 'Configure OAuth 2.0',
      access_token: 'Access Token URL',
      client_id: 'Client ID',
      client_secret: 'Client Secret Key',
      scope: 'Scope',
      save_oauth: 'Save OAuth Configuration',
      save_oauth_success: 'OAuth Token created successfully',
      save_oauth_error: 'There was an error while creating the OAuth Token',
      token_details: 'Token Details',
      create_webhook: 'Create WebHook',
      create_webhook_success: 'WebHook created successfully',
      create_webhook_error: 'There was an error while creating the WebHook',
    },
    update: {
      update_webhook: 'Update WebHook',
      update_webhook_success: 'WebHook updated successfully',
      update_webhook_error: 'There was an error while updating the WebHook',
    },
  },
  answerView: {
    alreadyRejectedSignature: 'You declined to sign this form',
    rejectConfirmationTitle: 'Are you sure you want to reject this request?',
    rejectConfirmationDescription: "Once rejected, you won't be able to sign this form again.",
    reject: 'Reject',
    rejectSuccess: 'Signature request rejected!',
    rejectError: 'Could not reject the signature',
    location: 'Location',
    address: 'Address',
    assignee: 'Assignee',
    taskInformation: 'Assigned Task Information',
    description: 'Description',
    tasks: 'Tasks',
    answeredFromMobile: 'Answered from mobile',
    answeredFromWeb: 'Answered from web',
    verifySignatureAlert:
      'To sign the form you must verify your signature. You only need to do this once.',
    verifySignature: 'Verify signature',
    configureSignatureAlert:
      'To sign the form you must configure and verify your signature. You only need to do this once.',
    configureSignature: 'Configure electronic signature',
    lackOfPermissions:
      'You do not have permission to edit your profile. Please request that the administrator completes your information so you can continue.',
    sign: 'Sign',
    alreadySignedAlert: 'You have already signed this form',
    rejectedAlert: 'You rejected this signature request',
    goToSignatures: 'View all Signatures',
    signatureConfigAlert: 'Please check your signature configuration',
    signSuccess: 'Form signed successfully!',
    signError: 'There was an error signing the form',
    signatureVerifySuccess:
      'You have successfully verified your electronic signature! Now you will be able to sign all your forms',
    signatureVerifyError: 'There was an error verifying your electronic signature',
    checkedCountInfo: 'selected items',
    checkedCountListLink: 'View list',
    group: 'Group',
    attachedFiles: 'These are the files attached to this question',
    editAnswer: 'Edit Answer',
    editAnswerDisabled: 'You cannot edit the answer because it has a signature request',
    metadataComments: {
      item: 'Item',
      answer: 'Answer',
      description: 'Description',
      images: 'Images',
      issues: 'Issues',
      viewIssue: 'View Issue',
    },
    expiredLink: {
      description: 'This link has expired. Request a new one by clicking the button',
      button: 'Request link',
      newLinkRequestedSuccess: 'The link has been sent to your email',
      newLinkRequestedError: 'There was an error sending the link',
    },
    externalUserSign: {
      title: 'Register your signature',
      registeredDescription:
        'Your signature is registered, please confirm your details to continue',
      unregisteredDescription:
        'Your signature is not registered; to sign, please complete the following details',
      name: 'Full name',
      company: 'Company',
      role: 'Position',
      confirm: 'Continue',
      cancel: 'Cancel',
      success: 'Form signed successfully!',
      error: 'There was an error signing the form',
    },
    locationInfo: {
      title: 'Detected location information',
      description: 'The answer was detected in a location from your list of locations',
      viewInMap: 'View in map',
      nameAndDistance: 'Name and distance',
      distanceHelpIcon:
        'Shows the distance between the selected location in the answer and the registered location when answering',
      awayFromLocation: 'km away from the location',
    },
    signature: {
      name: 'Name',
      idNumber: {
        CL: 'RUT',
        PE: 'DNI',
        MX: 'CURP',
        CO: 'CC',
      },
      email: 'Email',
      date: 'Date',
    },
    backToHome: 'Back to Home',
    paywall: {
      title: 'You have reached the limit of answers. Upgrade your plan to see more',
      button: 'Upgrade plan',
    },
    downloadPdf: 'Download PDF',
  },
  electronic_signature: {
    verifySignature: 'Verify Signature',
    annex: 'Annex',
    sendCode: 'Send code',
    errorSendingCode: 'An error occurred while sending the code',
    successSendingCode: 'Code sent successfully',
    code_sent_to_email: 'We sent the verification code to your email:',
    resendCodeLabel: "Didn't receive the code?",
    resend: 'Resend',
    incorrect_code: 'Incorrect code, you have {remaining_attempts} attempts left',
    maxAttemptsReached: 'You have reached the maximum number of attempts, a new code has been sent',
    errorVerifyingCode: 'Error verifying the code',
    back: 'Back',
    share: 'Share',
    signatures: {
      title: 'My Signatures',
      signature_date: 'Signature Date',
      noPendingSignatures: 'Congratulations! You have no pending signatures at this time',
      noCompletedSignatures: 'You have no completed signatures at this time',
      selected: 'Selected',
      sign: 'Sign',
      viewAndDownload: 'View and download',
      massiveSignError: 'There was an error signing the forms',
      massiveSignSuccess: 'Forms signed successfully',
      downloadError: 'There was an error downloading the file',
    },
    requests: {
      title: 'Sent Requests',
      mandatory: 'Required signature',
      optional: 'Optional signature',
      ongoing: 'Ongoing',
      completed: 'Completed',
      searchPlaceholder: 'Search by user or form...',
      form: 'Form',
      requestDate: 'Request Date',
      requestedBy: 'Requested by',
      goToSign: 'Go to sign',
      signatureDate: 'Signature Date',
      signatures: 'Signatures',
      signed: 'Signed',
      deleteRequest: 'Delete Request',
      pending: 'Pending',
      actions: 'Actions',
      deleteSuccess: 'Request deleted successfully',
      deleteError: 'There was an error deleting the request',
      noOngoingRequests: 'All set! You have no ongoing requests at the moment.',
      noCompletedRequests: 'You have no completed requests at the moment.',
      shareModal: {
        title: 'Share completed signature request',
        share: 'Share',
        infoAlert:
          'A file will be sent by email. Recipients will be able to access and review the details easily.',
        emailInstruction: 'Enter the email address where you want to send the file.',
        inputInstruction: 'You can quickly add tasks by separating them with commas ",".',
        cancel: 'Cancel',
      },
      shareSuccess: 'Request shared successfully',
      shareError: 'There was an error sharing the request',
      deleteWarningTitle: 'Are you sure you want to delete this request?',
      deleteWarningMessage:
        'Signatures that have already been completed will remain valid and will be saved in the history.',
      timeline: {
        title: 'Request details',
        close: 'Close',
        createdRequired: '{user} has sent a mandatory signature request',
        createdOptional: '{user} has sent an optional signature request',
        createdRequiredSimultaneous: '{user} has sent a mandatory signature request to:',
        createdOptionalSimultaneous: '{user} has sent an optional signature request to:',
        createdRequiredSequential: '{user} has sent a mandatory signature request with this order:',
        createdOptionalSequential: '{user} has sent an optional signature request with this order:',
        signed: '{user} signed the document',
        signedOrder: '{user} signed the document ({number} signers)',
        signedOrderGroup: '{user} signed the document ({number} signers of group {group})',
        rejected: '{user} rejected the request',
        signatureRequested: 'Request sent to {user} ({number} signers)',
        signatureRequestedGroupSingular: 'Request sent to {users} (Signer group {group})',
        signatureRequestedGroupPlural: 'Request sent to {users} (Signers group {group})',
        completed: 'All required signatures have been completed. The document is now ready',
        signer: 'Signer',
        group: 'Group',
      },
      tooltip: {
        signed: 'Request completed',
        rejected: 'Request rejected',
      },
    },
    promo: {
      title: 'Sign reports digitally and ensure processes',
      description:
        'With the Signatures module, you can request and sign processes simply and securely.',
      actionButton: 'I want to implement Signatures!',
      moreInfoTitle: 'Ensure full traceability and minimize errors',
      infoPoint1: 'Request signatures from your field team and send automatic notifications',
      infoPoint2: 'Ensure the compliance of your processes',
      infoPoint3: 'Approved by the Labor Department (valid only in Chile)',
      infoPoint4: 'Access your data securely from any device',
      moreInfoButton: 'I want more information!',
      moreInfoLink:
        'https://help.mydatascope.com/en/articles/8728763-verified-electronic-signature',
    },
  },
  resume: {
    stats: {
      assignedTasks: 'Assigned Tasks',
      completedTasks: 'Completed Tasks',
      forms: 'Answered Forms',
      totalForms: 'Total Forms',
      locations: 'Visited Locations',
      totalLocations: 'Total Locations',
    },
    emptyState: {
      description:
        "You haven't answered any form yet! Start optimizing your processes by creating the first one",
      createFirstForm: 'Create a form now',
    },
    statuses: {
      pending: 'Pending',
      accepted: 'Accepted',
      rejected: 'Rejected',
      noStatus: 'No Status',
    },
    table: {
      configure: 'Configure',
      viewAnswer: 'View Answer',
      form: 'Form',
      code: 'Code',
      user: 'User',
      date: 'Date',
      status: 'Status',
      actions: 'Actions',
      changeLog: 'Change Log',
      modifyAnswer: 'Modify Answer',
      chat: 'Chat',
      sendToGoogleSheets: 'Send to Google Sheets',
      sendBackupToEmail: 'Send backup to email',
      downloadPdf: 'Download PDF',
      duplicateAnswer: 'Duplicate Answer',
      statusUpdateError: 'There was an error updating the status of the answer',
      noResults: 'No form answers found that match the applied filters',
      deleteAnswer: 'Delete',
      recoverAnswer: 'Recover',
    },
    bulkActions: {
      delete: 'Delete',
      changeStatus: 'Change Status',
      selected: 'selected',
      confirmDelete: 'Are you sure you want to delete {count} form answers?',
      confirmDeleteDescription: 'Once deleted, they will not be recoverable',
      cancel: 'Cancel',
      selectStatus: 'Select Status',
      select: 'Select',
      statuses: 'Statuses',
      selectStatusPlaceholder: 'Select a status',
      statusesUpdated: 'Statuses updated successfully!',
      errorUpdatingStatuses: 'Error updating statuses',
      deleteSuccess: 'Answers deleted successfully!',
      deleteError: 'Error deleting answers',
      recover: 'Recover',
      recoverSuccess: 'Answers recovered successfully!',
      recoverError: 'Error recovering answers',
    },
    filters: {
      addFilter: 'Add Filter',
      clearFilters: 'Clear Filters',
      dateRange: {
        title: 'Date',
        popoverTitle: 'Filter by date',
      },
      location: {
        title: 'Location',
        popoverTitle: 'Filter by location',
        noLocations: 'No locations found',
      },
      form: {
        title: 'Form',
        popoverTitle: 'Filter by form',
        activeTab: 'Active',
        inactiveTab: 'Inactive',
        deletedTab: 'Deleted',
      },
      status: {
        title: 'Status',
        popoverTitle: 'Filter by status',
      },
      user: {
        title: 'User',
        popoverTitle: 'Filter by user',
        allTab: 'Users',
        groupTab: 'Groups',
      },
      formCode: {
        title: 'Form Code',
        popoverTitle: 'Filter by form code',
        placeholder: 'Enter form code',
      },
      questionAndAnswer: {
        title: 'Question and Answer',
        popoverTitle: 'Filter by question and answer',
        questionLabel: 'Question',
        questionPlaceholder: 'Select a question',
        questionTextInputPlaceholder: 'Write the question name',
        answerLabel: 'Answer',
        answerDisabledPlaceholder: 'Select a question to activate the filter',
        answerTextInputPlaceholder: 'Write a question name to activate the filter',
        answerPlaceholder: 'Write the answer',
        info: 'The filter will only apply if there is a question and an answer',
        disabledTooltip: 'To use this filter, you must first add a form filter',
      },
      finding: {
        title: 'Issue',
        popoverTitle: 'Filter by issue',
        includesFinding: 'Includes issue',
        withoutFindings: 'Without issues',
      },
      signature: {
        title: 'Signature',
        popoverTitle: 'Filter by signature',
      },
      answeredFrom: {
        title: 'Answered from',
        popoverTitle: 'Filter by answered from',
        mobile: 'Mobile',
        web: 'Web',
      },
    },
    signature: {
      mandatoryIncomplete: 'Mandatory signature - Incomplete',
      mandatoryComplete: 'Mandatory signature - Complete',
      optionalIncomplete: 'Optional signature - Incomplete',
      optionalComplete: 'Optional signature - Complete',
    },
    recentTitle: 'Recent answers',
    deletedTitle: 'Deleted answers',
    searchBarPlaceholder: 'Search...',
    dateRange: 'Showing results for',
    changeLog: {
      title: 'Change Log',
      details: 'View details',
      userChangedAnswer: '{user} changed the answer',
      userChangedState: '{user} changed the status of the answer to {state}',
      userRecoveredAnswer: '{user} recovered the answer',
      userDeletedAnswer: '{user} deleted the answer',
      userDuplicatedAnswer: '{user} duplicated the answer',
      userStartedAnswer: '{user} started to answer',
      userResetState: '{user} reset the status of the answer',
    },
    close: 'Close',
    export: {
      title: 'Export',
      taskForm: 'Form',
      dateRange: 'Date range',
      downloadFormat: 'Download format',
      email: 'Email',
      downloadFormats: {
        answers: 'Excel grouped by answer',
        forms: 'Excel grouped by form',
        subforms: 'Excel grouped by repeatable fields',
        pdfs: 'PDF documents',
      },
      exportInfo: 'The answers will be exported according to the table filters',
      exportFormFilterRequired: 'To export the answers, you must first filter by form',
      exportDateRangeLimit: 'Export limit: 180 days (6 months). Select a shorter date range',
      cancel: 'Cancel',
      success: 'Export sent successfully',
      error: 'There was an error exporting the answers',
      disabledForFreeUser: 'Update your plan to enable data export',
    },
    sendToGoogleSheets: {
      success: 'Sent to Google Sheets successfully',
      error: 'There was an error sending the answer to Google Sheets',
    },
    duplicateAnswer: {
      success: 'Answer duplicated successfully!',
      error: 'Error duplicating answer',
    },
    sendBackupModal: {
      title: 'Send backup to email',
      description: 'Write the email where you want to receive the backup of this form',
      send: 'Send',
      cancel: 'Cancel',
    },
    deleteAnswer: {
      title: 'Are you sure you want to delete this answer?',
      description:
        'Once deleted, it will not be recoverable. You can recover it by filtering by deleted answers and restoring it from its configuration.',
      confirm: 'Delete',
      cancel: 'Cancel',
      success: 'Answer deleted successfully!',
      error: 'Error deleting answer',
    },
    recoverAnswer: {
      success: 'Answer recovered successfully!',
      error: 'Error recovering answer',
    },
    statusSelector: {
      newStatus: 'Select or create a status',
      newStatusPlaceholder: 'Write here the new status',
      newStatusError: 'The status already exists',
      delete: 'Delete',
      color: 'Color',
      colors: {
        white: 'White',
        blue: 'Blue',
        yellow: 'Yellow',
        red: 'Red',
        green: 'Green',
        gray: 'Gray',
      },
      editStatusError: 'Error updating status',
      deleteStatusError: 'Error deleting status',
      createStatusError: 'Error creating status',
      deleteConfirmationTitle: 'Are you sure you want to delete this status?',
      deleteConfirmationMessage:
        'Once deleted, it will not be recoverable. But the answers that use it will not be affected.',
    },
    chat: {
      notifyUsersError: 'Error notifying users',
    },
    filtersLinkCopy: {
      tooltip: 'Copy link with filters',
      success: 'Link copied to clipboard',
    },
    answerView: {
      previous: 'Previous',
      next: 'Next',
    },
  },
};
