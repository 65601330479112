import { client } from './index';

export interface PremiumFlag {
  status: 'demo' | 'active' | 'expired' | 'cancelled';
  trial_days?: number;
  activation_date: Date;
}
interface FeatureFlagsInterface {
  flags: string[];
  premium_flags?: Record<string, PremiumFlag>;
}

interface PermissionsInterface {
  permissions: string[];
}

export interface CurrentUser {
  id: number;
  username: string;
  email: string;
  last_name: string;
  is_support?: boolean;
  is_admin?: boolean;
  is_superadmin?: boolean;
  is_paying?: boolean;
  free_form_answers?: number[];
  project?: {
    name: string;
    block_editing_creating_forms: boolean;
    forms_allowed_per_account: number;
  };
}

async function getCurrentUserFlags() {
  const response = await client.get<FeatureFlagsInterface>('/users/show_feature_flags');
  const { data } = response;
  return data;
}

async function getCurrentUserPermissions() {
  const response = await client.get<PermissionsInterface>('/show_permission_flags');
  const { data } = response;
  return data.permissions;
}

async function currentUserLogout() {
  const response = await client.get('/react_logout');
  const { data } = response;
  return data;
}

async function getCurrentUserName() {
  const response = await client.get<CurrentUser>('/react_get_username');
  const { data } = response;
  return data;
}

export const currentUserApi = {
  getCurrentUserFlags,
  getCurrentUserPermissions,
  currentUserLogout,
  getCurrentUserName,
};
