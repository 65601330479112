import axios from 'axios';

export const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  withCredentials: true,
});

export * from './tasksForms';
export * from './currentTaskForm';
export * from './currentUser';
export * from './mobileUsers';
export * from './locations';
export * from './billing';
export * from './webforms';
export * from './taskAssigns';
export * from './lists';
export * from './findings';
export * from './formAnswers';
export * from './signature';
