import { nitRegex, nuipRegex, rucRegex, rutRegex } from './validations';

export const module11 = (taxIdNumber: string) => {
  const taxIdNumberReversed = taxIdNumber.split('').reverse();
  const verificationDigit = taxIdNumberReversed.reduce(
    (acc, number, index) => acc + Number(number) * ((index % 6) + 2),
    0,
  );

  return 11 - (verificationDigit % 11);
};

export const getVerificationDigitRutChile = (verificationDigit: number) => {
  if (verificationDigit === 11) return '0';
  if (verificationDigit === 10) return 'K';
  return verificationDigit.toString();
};

export const checkRutChile = (rut: string) => {
  if (rut === '') return true;
  if (!rutRegex.test(rut)) return false;
  const rutSplit = rut.split('-');
  const rutNumbers = rutSplit[0].replace(/\./g, '');
  const rutVerificationDigit = rutSplit[1];
  return rutVerificationDigit === getVerificationDigitRutChile(module11(rutNumbers));
};

export const checkRucPeru = (ruc: string) => {
  if (ruc === '') return true;
  if (!rucRegex.test(ruc)) return false;
  const rucNumber = ruc.slice(0, 10);
  const rucVerificationDigit = ruc.slice(-1);
  return rucVerificationDigit === (module11(rucNumber) % 10).toString();
};

export const checkNitColombia = (nit: string) => {
  const weights = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
  if (nit === '') return true;
  if (!nitRegex.test(nit)) return false;
  const nitFormatted = nit.replace(/\./g, '');
  const nitNumbers = nitFormatted.slice(0, -2);
  const nitVerificationDigit = parseInt(nitFormatted.slice(-1));
  const nitLength = nitNumbers.length;
  if (nitLength > 15) return false;
  const sum = [...nitNumbers].reduce((acc, digit, i) => {
    const weight = weights[nitLength - i - 1];
    return acc + parseInt(digit) * weight;
  }, 0);
  const verificationDigit = sum % 11 > 1 ? 11 - (sum % 11) : sum % 11;
  return verificationDigit === nitVerificationDigit;
};

export const checkNuipColombia = (nuip: string) => {
  const nuipFormatted = nuip.replace(/\./g, '');
  if (nuipFormatted.length > 10 || nuipFormatted.length < 6) return false;
  return nuipRegex.test(nuipFormatted);
};

export const formatRutChile = (input: string) => {
  // ignore all characters except numbers and k/K &
  // ignore possible zeroes at the beginning of the number
  const formattedIDNumber = input.replace(/[^0-9kK]/g, '').replace(/^0+/, '');
  const idNumbers = formattedIDNumber.slice(0, -1);
  const verificationDigit = formattedIDNumber.slice(-1).toUpperCase();
  if (idNumbers === '' && verificationDigit === '') return '';
  // Add points to the RUT number
  const numbers = idNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // Join the formatted number and verification digit with a hyphen
  return numbers + '-' + verificationDigit;
};

export const formatRucPeru = (input: string) => {
  const formattedRuc = input.replace(/[^0-9]/g, '');
  if (formattedRuc === '') return '';
  return formattedRuc;
};

export const formatNitColombia = (input: string) => {
  const formattedIDNumber = input.replace(/[^0-9]/g, '').replace(/^0+/, '');
  const idNumbers = formattedIDNumber.slice(0, -1);
  const verificationDigit = formattedIDNumber.slice(-1);
  if (idNumbers === '' && verificationDigit === '') return '';
  // Add points to the RUT number
  const numbers = idNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // Join the formatted number and verification digit with a hyphen
  return numbers + '-' + verificationDigit;
};

export const formatNuipColombia = (input: string) => {
  const formattedNuip = input.replace(/[^0-9]/g, '');
  return formattedNuip;
};
