import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { collection, getDocs, query, where } from 'firebase/firestore';

import { useFeatureFlags } from '../../hooks';
import { useFirebaseStore } from '../../newStore/firebaseStore';
import { Finding } from '../../types';
import { getQueryParams } from '../../utils/utils';

interface AnswerViewContextType {
  findingsList: Finding[];
  isPublicView: boolean;
  taskFormId: string;
  formAnswerId: string;
  isEmbedded?: boolean;
}

const AnswerViewContext = createContext<AnswerViewContextType | null>(null);

interface AnswerViewProviderProps {
  children: ReactNode;
  formAnswerId?: string | number;
  taskFormId?: string | number;
  isEmbedded?: boolean;
}

export const AnswerViewProvider = ({
  children,
  formAnswerId: propFormAnswerId,
  taskFormId: propTaskFormId,
  isEmbedded = false,
}: AnswerViewProviderProps) => {
  const { form_answer_id: urlFormAnswerId, token, task_form_id: urlTaskFormId } = getQueryParams();

  const formAnswerId = propFormAnswerId?.toString() || urlFormAnswerId;
  const taskFormId = propTaskFormId?.toString() || urlTaskFormId;
  const isPublicView = !!token;

  const { firestore, user, firebaseConfigured } = useFirebaseStore();
  const { getPremiumFlag } = useFeatureFlags();
  const findingsActivated = !!getPremiumFlag('Findings');

  const [findingsList, setFindingsList] = useState<Finding[]>([]);

  useEffect(() => {
    async function getFindings() {
      try {
        const findingsQuery = query(
          collection(firestore, `datascope/${user.uid}/findings`),
          where('form_answer.id', '==', parseInt(formAnswerId)),
        );

        const findingsSnapshot = await getDocs(findingsQuery);
        const findingsList = findingsSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          } as Finding;
        });
        setFindingsList(findingsList);
      } catch (error) {
        console.error('Error fetching findings:', error);
      }
    }
    if (!isPublicView && findingsActivated && firebaseConfigured) getFindings();
  }, [firebaseConfigured]);

  const answerViewValue = {
    findingsList,
    isPublicView,
    taskFormId,
    formAnswerId,
    isEmbedded,
  };
  return (
    <AnswerViewContext.Provider value={answerViewValue}>{children}</AnswerViewContext.Provider>
  );
};

export const useAnswerViewContext = () => useContext(AnswerViewContext);
