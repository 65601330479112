import { useUserDataStore } from '../newStore/userDataStore';

export function useCurrentUser() {
  const currentUser = useUserDataStore((state) => state.currentUser);

  const isFreeUser = currentUser && !currentUser.is_paying && !currentUser.is_support;

  function formAnswerIsBlocked(formAnswerId: number) {
    return isFreeUser && !currentUser.free_form_answers?.includes(formAnswerId);
  }

  return {
    isFreeUser,
    formAnswerIsBlocked,
  };
}
